@charset "utf-8";
/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer{
  background: $main_color;
  padding: 8rem 5%;
  &__inner{
    justify-content: space-between;
    .lbox{
      width: 30%;
      .logo{
        margin-bottom: 2rem;
      }
      p{
        line-height: 1;
        font-size: 1.2rem;
        line-height: 1;
        color: #fff;
        margin-bottom: 2rem;
      }
      .sns{
        justify-content: flex-start;
        margin-bottom: 2.3rem;
        li{
          line-height: 1;
          margin-right: 0.5em;
          a{
            display: block;
            height: 16px;
            @include mq(sm-){
              &:hover{
                transform: translateY(-0.5rem);
              }
            }
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
      .copy{
        font-size: 1.5rem;
        color: #fff;
      }
    }
    .rbox{
      width: 44.5%;
      line-height: 1;
      align-items: flex-start;
      @include mq(w1500){
        width: 64.5%;
      }
      .f_link{
        li{
          width: 100%;
          font-size: 1.5rem;
          &:nth-child(n+2){
            margin-top: 2em;
          }
          &.c-btn02{
            max-width: 100%;
            a{
              border: 1px solid #fff;
            }
          }
          a,p{
            display: inline-block;
            color: #fff;
          }
          a{
            position: relative;
            &:after{
              content: "";
              background:#fff;
              width: 0%;
              height: 2px;
              display: block;
              position: absolute;
              bottom:-0.3em;
              left: 0;
              transition-duration: 0.3s;
            }
            &:hover:after{
              width: 100%;
            }
          }
          p{
            margin-bottom: 2em;
          }

          .in_list{
            li{
              position: relative;
              padding-left:1em;
              &:before{
                content: "";
                background: #fff;
                width: 5px;
                height: 1px;
                display: block;
                position: absolute;
                left: 0;
                top:50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
      .lf{
        width: 37%;
      }
      .md{
        width: 35%;
        position: relative;
        &:before{
          content: "";
          background:#757E8D;
          width: 1px;
          height: 100%;
          display: block;
          position: absolute;
          left: -5rem;
          top:0;
        }
      }
      .rt{
        width: 28%;
      }
    }
  }
  @include mq(sm){
    padding: 5rem 5%;
    &__inner{
      .lbox{
        width: 100%;
        text-align: center;
        .sns{
          justify-content: center;
          li{

            a{


            }
          }
        }
      }
    }
  }
}
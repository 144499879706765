@charset "utf-8";
/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
@include mq(sm-){
  .l-gnavi{
    &__menu{
      @include mq(w1300){
        margin-left: auto;
        margin-top: 0.5em;
      }
      li{
        &:nth-child(n+2){
          margin-left: 5rem;
        }
        a{
          display: block;
          font-size: 1.5rem;
          position: relative;
          &:before{
            content: "";
            background:$main_color;
            width: 0%;
            height: 3px;
            display: block;
            position: absolute;
            bottom:0;
            left: 0;
            transition-duration: 0.3s;
          }
          &:hover:before{
            width: 100%;
          }
        }
      }
    }
  }
}

@include mq(sm){
  .l-header__menu{
    align-items: flex-start;
  }
  .l-header__toggle {
    position: relative;
    z-index: 103;
  }
  .l-gnavi{
    align-items: flex-start;
    padding: calc(4rem + 4em + 1rem) 4rem 4rem;
    &__menu{
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
      margin-bottom: 2em;
      padding-bottom:2em;
      border-bottom: 1px solid #676a6d;
      li{
        width: 49%;
        text-align: center;
        line-height: 1;
        &:nth-child(n+3){
          margin-top: 1rem;
        }
        a{
          border-radius: 10rem;
          color: $main_color;
          display:block;
          background:#fff;
          padding:1.5em 1em;
          font-weight: 600;
        }
      }
    }
    .nav-sns{
      justify-content: center;
      margin: auto;
      li{
        &:nth-child(n+2){
          margin-left: 1em;
        }
        a{
          padding: 2em;
          display: block;
          background: #fff;
          border-radius:50%;
          position: relative;
          img{
            max-width: 100%;
            position: absolute;
            left: 50%;
            top:50%;
            transform: translate(-50%,-50%);
          }
        }
      }
    }
  }
}
@include mq(xs){
  .l-gnavi{
    &__menu{
      padding-bottom:4rem;
      margin-bottom: 4rem;
      li{
        width: 100%;
        &:nth-child(n+2){
          margin-top: 1rem;
        }
      }
    }
  }
}

@charset "utf-8";
/*--------------------------------------------
  HEADER
--------------------------------------------*/
/*test*/

.-under.test .l-header {
    border-bottom:none;
    margin-bottom: 0rem;
}
.-under.test article {
    padding-top: 0rem;
}
.-under.test .c-online a {
    width: 15.5rem;
    padding-top: 15.5rem;
    border-radius: 50%;
    position: relative;
    background: #595959;
    display: block;
    font-size: 1.7rem;
    color: #fff;
    line-height: 1.4;
    text-align: center;
    border: 2px solid #595959;
}
.-under.test .c-online.bg02 a {
    width: 15.5rem;
    padding-top: 15.5rem;
    border-radius: 50%;
    position: relative;
    background: #595959;
    display: block;
    font-size: 1.7rem;
    color: #fff;
    line-height: 1.4;
    text-align: center;
    border: 2px solid #fff;
}
@include mq(xs){
  .-under.test .c-online a{
    width: 4rem;
    padding: 4rem;
  }
  .-under.test .c-online.bg02 a{
    width: 4rem;
    padding: 4rem;
  }
}


// .-under.test .c-online a span:before {
//     content: "";
//     background: url('/assets/img/common/icon_cart_bk.png') no-repeat;
//     width: 62px;
//     height: 53px;
//     display: block;
//     margin-bottom: .5em;
// }



@include mq(sm-){
  .l-header{
    padding:4rem 5%;
    align-items: center;
    &__logo{
      a{
        display: block;
        img{
          max-width: 100%;
        }
        @include mq(w1500){
          width: 70%;
        }
        @include mq(sm-){
          &:hover{
            opacity: 0.6;
          }
        }
      }
    }
    .h-inner{
      align-items: flex-end;
    }
    &_right{
      width: 72%;
      @include mq(w1300-){
        display: flex;
        justify-content: space-between;
      }
    }
    p{
      line-height: 1;
      margin-top: 0.5em;
      font-size: 1.2rem;
      color: #5c5c5c;
    }
    &__menu{
      margin-left: auto;
      order: 1;
    }
    .inquery{
      align-items: center;
      margin-left: 3.5rem;
      width: 21%;
      order: 2;
      @include mq(w1500){
        width: 32%;
        margin-left: auto;
      }
      .c-btn02{
        width: 58%;
      }
      .h-sns{
        align-items:center;
        justify-content: space-between;
        width: 60%;
        margin-left: auto;
        .c-img_ofi{
        padding-top: 0;
        }

        li{
          width: 25%;
          line-height: 1;
          a{
            display: block;
            height: 25px;
            transition-duration: 0.3s;
            img{
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
            &:hover{
              transform: translateY(-0.5rem);
            }
          }
        }
      }
    }
  }
  .-under .l-header{
    border-bottom:1px solid #B3B3B3;
    margin-bottom: 0rem;
  }
}

@include mq(sm){
  .l-header{
    justify-content: space-between;
    align-items:center;
    padding:0.5em;
    .h-inner{
      p{
        padding-top:0.5em;
      }
    }
  }
}
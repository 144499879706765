@charset "utf-8";


/*--------------------------------------------
  ラッピング
--------------------------------------------*/
.p-wrapping_block{
  section:nth-child(n+2){
    margin-top: 3em;
  }
  p{
    &:nth-child(n+2){
      margin-top: 1em;
    }
  }
}

.p-wrapping_cont{
  &__flex{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      align-items: stretch;
    }
    margin-top: 1em;
    div{
      @include mq(xs-){
        width: 48%;
      }
      img{
        max-width: 100%;
      }
    }
    &.-clm3{
      @include mq(xs-){
        div{
          width: 30%;
          p{
            font-size: 0.9em;
            margin-top: 0.5em;
          }
        }
      }
    }
  }
  @include mq(xs){
    &__flex{
      div{
        &:nth-child(n+2){
          margin-top: 1em;
        }
      }
    }
  }
}


/*--------------------------------------------
  特定商取引法に基づく表記
--------------------------------------------*/
.p-law_list{
  padding-bottom: 10rem;
}

.p-law_pay{
  padding-bottom: 10rem;
}

.p-law_ship{
  padding-bottom: 10rem;
  .c-tbl{
    th{
      @include mq(xs-){
        &:first-child{
          width: 20%;
        }
        &:nth-child(2){
          text-align: left;
          width: 50%;
        }
      }
      td{
        width: 30%;
      }
    }
    @include mq(xs){
      th:first-child{
        text-align:center;
      }
    }
  }
}

/*--------------------------------------------
  オンライン
--------------------------------------------*/
.p-online{
  padding-top: 0;
}
.p-online_single{
  flex-direction: row-reverse;
  @include mq(sm){
    .c-side {
      margin-top: 1.5em;
      .cate{
        margin-top: 1.5em;
        padding-top: 1.5em;
        border-top: 1px solid #b6b6b6;
      }
    }
  }
}

/*--------------------------------------------
  404
--------------------------------------------*/

.p-notfound_main .c-ttl05{
  text-align: center;
}
.p-notfound_main p{
  margin-bottom: 1.5em;
  text-align: center;
}
.p-notfound_main .c-ttl01{
  text-align: center;
  display: block;
  margin: 0 auto 2rem;
}
.p-notfound_main .c-ttl01 .en{
  margin-bottom: 0.8em;
}
.p-notfound_main .c-btn02{
  margin: auto;
}
@media screen and (max-width : 767px ) {
  .p-notfound_main p{
    text-align: left;
  }
}

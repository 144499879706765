@charset "utf-8";
/*--------------------------------------------
  WordPress Native Styles
--------------------------------------------*/
strong { font-weight: bold; }
em { font-style: italic; }
blockquote {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px;
}
img.alignleft {
  margin: 0 auto 0 0;
}
img.aligncenter {
  display: block;
  margin: 0 auto;
}
img.alignright {
  display: block;
  margin: 0 0 0 auto;
}
img[class*="wp-image-"],
img[class*="attachment-"] {
  height: auto;
  max-width: 100%;
}



/*-----------------------------------------------------
  Wordpress EDITOR
------------------------------------------------------*/
.wp-editor{
  margin-bottom: 2em;
}

.wp-editor * + p,
.wp-editor * + ul,
.wp-editor * + ol,
.wp-editor * + table,
.wp-editor * + .c-tblwrap {
  margin-top: 2em;
}
.wp-editor * + h1,
.wp-editor * + h2,
.wp-editor * + h3,
.wp-editor * + h4,
.wp-editor * + h5,
.wp-editor * + h6 {
  margin-top: 3.5em;
}
.wp-editor h2,
.wp-editor h3,
.wp-editor h4,
.wp-editor h5,
.wp-editor h6 {
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: .1rem;
}
.wp-editor h2 {
  font-size: 2.8rem;
}
.wp-editor h3 {
  font-size: 2.4rem;
}
.wp-editor h4 {
  font-size: 2rem;
}
.wp-editor h5 {
  font-size: 1.8rem;
}
.wp-editor h6 {
  font-size: 1.6rem;
}
@include mq(xs){
  .wp-editor h2 {
    font-size: 2rem;
  }
  .wp-editor h3 {
    font-size: 1.8rem;
  }
  .wp-editor h4 {
    font-size: 1.8rem;
  }
  .wp-editor h5 {
    font-size: 1.6rem;
  }
  .wp-editor h6 {
    font-size: 1.6rem;
  }
}

.wp-editor a {
  color: $font_color;
  word-break: break-all;
  text-decoration: underline;
}
.wp-editor a:hover,
.wp-editor a:focus {
  opacity: .7;
}

.wp-editor ul,
.wp-editor ol {
  padding-left: 2em;
  line-height: 1.5;
}
.wp-editor ul > li:not(:first-child),
.wp-editor ol > li:not(:first-child) {
  margin-top: .6em;
}
.wp-editor ul > li {
  list-style-type: disc;
}
.wp-editor ol > li {
  list-style-type: decimal;
}

.wp-editor table {
  width: 100%;
}
.wp-editor table tr > th,
.wp-editor table tr > td {
  padding: 1em;
  border: 1px solid #ddd;
}
.wp-editor table tr > th {
  background-color: #a9a9a9;
}


@media screen and (max-width : 991px ) {
  .wp-editor * + p,
  .wp-editor * + ul,
  .wp-editor * + ol,
  .wp-editor * + table,
  .wp-editor * + .c-tblwrap {
    margin-top: 1.2em;
  }
  .wp-editor * + h1,
  .wp-editor * + h2,
  .wp-editor * + h3,
  .wp-editor * + h4,
  .wp-editor * + h5,
  .wp-editor * + h6 {
    margin-top: 2em;
  }
  .wp-editor ul > li:not(:first-child),
  .wp-editor ol > li:not(:first-child) {
    margin-top: .4em;
  }
}

/*--------------------------------------------
  PAGINATION
--------------------------------------------*/
.wp-pagenavi>*{
  border-radius: 0!important;
}
.wp-pagenavi{
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin-top:4em;
  a{
    background: $main_color;
    padding: 0.5em 1em!important;
    background: $main_color;
    border:1px solid  $main_color;
    color: #fff;
    @include mq(sm-){
      &:hover{
        background:#fff!important;
        color: $main_color!important;
      }   
    }
    &.previouspostslink{
      padding-left: 1em!important;
      margin-right: 0.5em!important;
      &:before,&:after{
        display: none!important;
      }
    }
    &.nextpostslink{
      padding-right: 1em!important;
      margin-left: 0.5em!important;
      &:before,&:after{
        display: none!important;
      }
    }
  }
  span{
    background: $main_color;
    padding: 0.5em 1em!important;
    background: $main_color;
    border:1px solid  $main_color;
  }
  @include mq(xs){
    .wp-pagenavi{
      font-size: 1.4rem!important;
      a{
        padding: .5em 0.6em!important;
      }
      span{
        padding: .5em 0.3em!important;
      }
    }
  }
}

@charset "utf-8";

.l-main {
  padding-bottom:10rem;
  @include mq(xs){
    padding-bottom:8rem;
  }
  .l-page.-under &{
    padding-top:10rem;
    &.-pdt0{
      padding-top: 0;
    }
    @include mq(xs){
      padding-top:8rem;
    }
  }
}

/*--------------------------------------------
  breadcrumb
--------------------------------------------*/
.l-breadcrumb {
  line-height:1;
  margin-bottom: 4rem;
  border-bottom:1px dotted $sub_color;
  &__inner{
    max-width: 1200px;
    margin: auto;
  }
  &__list{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    font-size: 1.4rem;
    li{
      padding:1em 0;
      a{
        display: inline-block;
        vertical-align: middle;
      }
      &:not(:last-child){
        margin-right:1em;
        font-weight: 400;
        a{
          padding-right: 1em;
          &:hover{
            color:$main_color;
          }
        }
        &:after{
          content: ">";
        }
      }
    }
  }
}



/*--------------------------------------------
  サイドバー
--------------------------------------------*/

.c-side{
  width: 21%;
  .serch_box{
    border-radius: 5px 0px 0px 5px;
  }
  input[type="submit"]{
    width: 20%;
    border-radius: 0px 5px 5px 0px;
    border:none;
    background: $main_color;
    color: #fff;
  }
  input[type="text"]{
    width: 80%;
    padding:0.5em;
  }
  .cate{
    margin-top: 1.5em;
    padding-top:1.5em;
    border-top:1px solid #B6B6B6;
    &:nth-child(n+3){
      border-top:none;
      margin-top: 0;
      padding-top: 0;
    }
    .ttl{
      font-size: 1.4rem;
      line-height: 1;
      margin-bottom: 1em;
    }
    &_list01{
      margin-bottom: 2rem;
      padding-bottom:1em;
      border-bottom:1px solid #B6B6B6;
      li{
        a{
          display: block;
          line-height: 1;
          padding: 0.5em 0 0.5em 0.9em;
          position: relative;
          &:before{
            content: "";
            background:url('/assets/img/common/icon_arrow_br04.png') no-repeat;
            width: 6px;
            height: 8px;
            display: block;
            position: absolute;
            left: 0;
            top:50%;
            transform: translateY(-50%);
            transition-duration: 0.3s;
          }
          @include mq(sm-){
            &:hover{
              &:before{
                left: 0.3em;
              }
            }
          }
        }
      }
      &.main_list{
        li{
          .cate_link{
            padding: 0.8em 0 0.5em 2em;
            &:before{
              width: 22px;
              height: 22px;
            }
            &.-fashion{
              &:before{
                background:url('/assets/img/common/icon_fation.png') no-repeat;
                height: 16px;
              }
            }
            &.-tableware{
              &:before{
                background:url('/assets/img/common/icon_table.png') no-repeat;
              }
            }
            &.-kitchen{
              &:before{
                background:url('/assets/img/common/icon_kitchin.png') no-repeat;
              }
            }
            &.-stationery{
              &:before{
                background:url('/assets/img/common/icon_stationery.png') no-repeat;
              }
            }
            &.-interior{
              &:before{
                background:url('/assets/img/common/icon_interior.png') no-repeat center;
              }
            }
            &.-kids-baby{
              &:before{
                background:url('/assets/img/common/icon_kids.png') no-repeat;
              }
            }
            &.-gift{
              &:before{
                background:url('/assets/img/common/icon_gift.png') no-repeat;
              }
            }
          }
          // &:nth-child(1){
          //   a{
          //     &:before{
          //       background:url('/assets/img/common/icon_fation.png') no-repeat;
          //       height: 16px;
          //     }
          //   }
          // }
          // &:nth-child(2){
          //   a{
          //     &:before{
          //       background:url('/assets/img/common/icon_table.png') no-repeat;
          //     }
          //   }
          // }
          // &:nth-child(3){
          //   a{
          //     &:before{
          //       background:url('/assets/img/common/icon_kitchin.png') no-repeat;
          //     }
          //   }
          // }
          // &:nth-child(4){
          //   a{
          //     &:before{
          //       background:url('/assets/img/common/icon_stationery.png') no-repeat;
          //     }
          //   }
          // }
          // &:nth-child(5){
          //   a{
          //     &:before{
          //       background:url('/assets/img/common/icon_interior.png') no-repeat center;
          //     }
          //   }
          // }
          // &:nth-child(6){
          //   a{
          //     &:before{
          //       background:url('/assets/img/common/icon_kids.png') no-repeat;
          //     }
          //   }
          // }
          // &:nth-child(7){
          //   a{
          //     &:before{
          //       background:url('/assets/img/common/icon_gift.png') no-repeat;
          //     }
          //   }
          // }
        }

        .child_list{
          padding-left: 1em;
          font-size: .75em;
        }
      }
    }
  }
  .bnr_area{
    a{
      display: block;
      img{
        max-width: 100%;
      }
      @include mq(sm-){
        &:hover{
          opacity: 0.6;
        }
      }
    }
  }
  @include mq(sm){
    width: 30%;
    input[type="submit"]{
      font-size: 1.4rem;
      padding: 0.4em;
    }
    input[type="text"]{
      font-size: 1.4rem;
    }
  }
  @include mq(xs){
    width: 100%;
    margin-top: 3rem;
    input[type="submit"]{
      width: 30%;
    }
    input[type="text"]{
      width: 70%;
    }

    .c-inner{
      width: 100%;
    }
    .bnr_area{
      text-align: center;
      a{
        display: inline-block;
      }
    }
  }
}
.p-products{
  .c-side{
    .cate{
      display: block!important;
    }
  }
}

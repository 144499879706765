@charset "utf-8";
/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/

.c-inner {
  width: 92%;
  max-width: $base_width;
  margin-left: auto;
  margin-right: auto;

  &.-full {
    width: 100%;
  }
}

/*--------------------------------------------
  online
--------------------------------------------*/
.c-item_sec{
  &:not(:last-child){
    margin-bottom: $margin_md;
  }
}
.c-anavi{
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  margin-bottom: $margin_sm;
  li{
    width: 48%;
    a{
      display: block;
      text-align: center;
      font-size: 1.125em;
      background: #ccc;
      color: #fff;
      padding: 1em;
      border-radius: .75em;
      &.is-act{
        background: #35414b;
      }
      @include mq(sm-){
        &:hover{
          opacity: .5;
        }
      }
    }
  }
}

.c-online{
  position: fixed;
  right: 2%;
  bottom:2%;
  z-index: 10;
  a{
    width: 15.5rem;
    padding-top: 15.5rem;
    border-radius: 50%;
    position: relative;
    background-color: rgba(0,0,0,0.7);
    display: block;
    font-size: 1.7rem;
    color: #fff;
    line-height: 1.4;
    text-align: center;
    span{
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%,-50%);
      &:before{
        content: "";
        background: url('/assets/img/common/icon_cart_w.png') no-repeat;
        width: 62px;
        height: 53px;
        display: block;
        margin-bottom: 0.5em;
      }
    }
    @include mq(sm-){
      &:hover{
        opacity: 0.6;
      }
    }
  }
  @include mq(sm){
    a{
      width: 13rem;
      padding-top:13rem;
      font-size: 1.5rem;
      span{
        &:before{
          background-size: contain;
          width: 49px;
          height: 42px;
          margin: 0 0.5em 0.8em;
        }
      }
    }
  }
  @include mq(xs){
    a{
      width: 4rem;
      padding:4rem;
      img{
        max-width: 50%;
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50% , -50%);
      }
    }
  }
}

.c-cate{
  max-width: 1200px;
  margin: 0 auto 4rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  li{
    margin-right: .5em;
    margin-bottom: .5em;
    width: 24%;
    text-align: center;
    a{
      padding:0.5em;
      background:$sub_color;
      display: block;
      border:1px solid $sub_color;
      border-radius: 5px;
      @include mq(sm-){
        &:hover{
          background:#fff;
        }
      }
    }
  }
  @include mq(sm){
    justify-content: space-between;
    li{
      margin-right: 0em;
      width: 49%;
      a{
        padding: 0.3em;
      }
    }
  }
  @include mq(xs){
    li{
      a{
        padding:0.5em;
        font-size: 1.4rem;
      }
    }
  }
}

/*--------------------------------------------
  under mv
--------------------------------------------*/
.p-under_key{
  background:$sub_color;
  text-align: center;
  font-weight: 400;
  line-height: 1;
  padding: 1.5em 0;
  .en{
    font-size: 4rem;
    margin-bottom: 0.3em;
  }
  .jp{
    font-size: 1em;
  }
  @include mq(sm){
    margin-bottom: 4rem;
  }
  @include mq(xs){
    margin-bottom: 3rem;
    .en{
      font-size: 3.5rem;
    }
  }
}

/*--------------------------------------------
  TITLE
--------------------------------------------*/
.en{
  font-family: $base_font_en;
}
.c-ttl01{
  display: flex;
  align-items:flex-end;
  line-height: 1;
  margin-bottom: 3.4rem;
  .en{
    font-size: 4rem;
    color: #37414f;
  }
  .jp{
    font-size: 1.4rem;
    color: #8f8f8f;
    padding-bottom:0.7em;
    margin-left: 1em;
  }
  .jp02{
    font-size: 3rem;
  }
  @include mq(xs){
    display: block;
    text-align: center;
    margin-bottom: 2.5rem;
    .en{
      margin-bottom: 0.4em;
      font-size: 3rem;
    }
    .jp02{
      font-size: 2.5rem;
    }
  }
}

.c-ttl02{
  display:flex;
  align-items:flex-end;
  line-height: 1;
  margin-bottom: 1.9rem;
  .en{
    font-size: 4rem;
    color: #37414f;
  }
  .jp{
    font-size: 1.4rem;
    color: #8f8f8f;
    padding-bottom:0.7em;
    margin-left: 1em;
  }
  @include mq(xs){
    .en{
      font-size: 3.3rem;
    }
  }
}

.c-ttl03{
  text-align: center;
  line-height: 1;
  .sm{
    color: #8f8f8f;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
  }
  .lg{
    font-size: 2.4rem;
    position: relative;
    margin-bottom: 4rem;
    &:before{
      content: "";
      background:url('/assets/img/common/icon_arrow_br03.png') no-repeat;
      width: 12px;
      height: 10px;
      display: block;
      position: absolute;
      right: 0;
      bottom:0;
    }
  }
}

.c-ttl04{
  border:1px solid #35414B;
  display: inline-block;
  padding:1em;
  margin-bottom: 1em;
}

.c-ttl05{
  font-size: 1.8rem;
  background: $sub_color;
  padding: 0.8em;
  margin-bottom: 1em;
  border-left:5px solid $main_color;
  > *{
    font-weight: 400;
  }
}

/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/

.c-btn01{
  font-size: 1.5rem;
  position: relative;
  &:before{
    content: "";
    background:#2C2C2C;
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    left: 0;
    bottom:0;
  }
  a{
    display: block;
    position: relative;
    line-height: 1;
    padding:1em 4em 1em 0;
    &:before{
      content: "";
      background:url('/assets/img/common/icon_arrow_br02.png') no-repeat;
      width: 14px;
      height: 11px;
      display: block;
      position: absolute;
      right: 0;
      top:50%;
      transform: translateY(-50%);
    }
    @include mq(sm-){
      &:hover{
        opacity: 0.6;
      }
    }
  }
}

.c-btn02{
  max-width: 200px;
  width: 100%;
  text-align: center;
  a{
    display: block;
    background: $main_color;
    border:1px solid $main_color;
    color: #fff;
    line-height: 1;
    padding:1.8rem;
    border-radius: 5rem;
    font-size: 1.5rem;
    @include mq(sm-){
      &:hover{
        background: #fff;
        color: $main_color;
      }
    }
  }
  &.contact{
    a{
      span{
        position: relative;
        padding-left:1.5em;
        &:before{
          content: "";
          background:url('/assets/img/common/icon_mail_wh.png') no-repeat;
          background-size: contain;
          width: 17px;
          height: 12px;
          display: block;
          position: absolute;
          left: 0;
          top:50%;
          transform: translateY(-50%);
          transition-duration: 0.3s;
        }
      }
      @include mq(sm-){
        &:hover{
          background:#fff;
          color: $main_color;
          span{
            &:before{
              background:url('/assets/img/common/icon_mail_bl.png') no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}

input.skubutton{
  display: block;
  background:#D4644C;
  color: #fff;
  border-radius:5rem;
  padding: 1em !important;
  border:1px solid #D4644C!important;
  transition-duration: 0.3s;
  @include mq(sm-){
    &:hover{
      background: #fff;
      color: #D4644C;
    }
  }
}

.c-wrappig_btn{
  a{
    font-weight: 600;
    font-size: 1.5rem;
    text-decoration: underline;
    line-height: 1;
    &:before{
      content: "";
      background: $font_color;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 0.3em;
    }
    @include mq(sm-){
      &:hover{
        text-decoration: none;
      }
    }
  }
}

/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden;

  .ofi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    &.cover{
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover;object-position:center center;';
    }

    &.contain {
      margin: auto;
      object-fit: contain;
      font-family: 'object-fit: contain;object-position:center center;';
    }
  }
}


/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto;
}

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.c-ta_left {
  text-align: left;
}

.c-ta_center {
  text-align: center;
}

.c-ta_right {
  text-align: right;
}

.c-pe_none {
  pointer-events: none;
}


.c-flex{
  display: flex;
  justify-content: flex-start;
  align-items:stretch;
  flex-wrap: wrap;
}

@include mq(sm-) {
  .c-pe_sp {
    pointer-events: none;
    cursor: default;
  }
}

.-center{
  margin:0 auto;
}

/*--------------------------------------------
 PARTS - annotation
--------------------------------------------*/
ul.c-annotation{
  margin-top: 1em;
  font-size: 1.4rem;
  li{
    position: relative;
    padding-left:1em;
    line-height: 1.6;
    &:nth-child(n+2){
      margin-top: 0.5em;
    }
    &:before{
      content: "※";
      position: absolute;
      left: 0;
      top:0;
      display: block;
    }
  }
}

/*--------------------------------------------
 PARTS - table
--------------------------------------------*/
.c-tbl{
  margin-bottom: 1.5em;
  tr{
    border-bottom:1px solid #B3B3B3;
    th,td{
      padding:1em 2em;
      line-height: 1.5;
    }
    th{
      font-weight: 500;
      width: 30%;
      border-bottom:1px solid #35414B;
      span{
        color: #870b3f;
      }
    }
    td{
      width: 70%;
      p{
        font-size: 0.9em;
        color: #5e5e5e;
        margin-top: 0.5em;
      }
    }
  }
  @include mq(sm){
    tr{
      th,td{
        padding:1em;
        font-size: 1.5rem;
      }
      th{
        text-align: left;
      }
    }
  }
  @include mq(xs){
    tr{
      th,td{
        display: block;
        width: 100%;
      }
      th{
        background: #f3f0f0;
      }
    }
  }
}

.submit_area{
  text-align: center;
  input[type=submit]{
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    transition-duration: 0.3s;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    margin: auto;
    padding:1em;
    transition-duration: 0.3s;
  }
  .btn_submit{
    max-width: 30rem;
    width: 100%;
    border:2px solid $accent_color;
    background: $accent_color;
    margin: auto;
    @include mq(sm-){
      &:hover{
        color: $accent_color;
        background: #fff;
      }
    }
  }
  .back{
    background: #777;
    max-width: 30rem;
    width: 100%;
    border:2px solid #777;
    margin: auto 0;
    @include mq(sm-){
      &:hover{
        color: #777;
        background: #fff;
      }
    }
    @include mq(xs){
      margin: 1rem auto 0!important;
    }
  }
}
.p-thanks{
  .thanks{
    text-align: center;
    margin-bottom: 1.5em;
  }
  .c-btn01{
    max-width: 250px;
    margin: auto;
  }
}

/*--------------------------------------------
 PARTS - NEWS
--------------------------------------------*/
.c-news{
  width: 76%;
  .c-btn01:before{
    bottom: 2.9rem;
  }
  &__inner{
    &:nth-child(n+2){
      margin-top: 4em;;
    }
  }
  &__list{
    margin-bottom: 2em;
    a{
      display: block;
      @include mq(sm-){
        &:hover{
          opacity: 0.6;
        }
      }
    }
    li{
      width: 31%;
      @include mq(xs-){
        margin-right: 3.5%;
        &:nth-child(3n){
          margin-right: 0;
        }
        &:nth-child(n+4){
          margin-top: 3rem;
        }
      }
      .img{
        border-radius:10px;
        overflow: hidden;
        padding-top: 100%;
        margin-bottom: 0.7em;
      }
    }
    .cate{
      background:#384150;
      color: #fff;
      display: inline-block;
      line-height: 1;
      font-size: 1.1rem;
      padding: 0.7rem 1.5em;
      border-radius:5rem;
      margin-bottom: 0.8em;
    }
    .ttl{
      font-size: 1.9rem;
      line-height: 1.6;
      margin-bottom: 0.5em;
    }
    p{
      font-size: 1.4rem;
      margin-bottom: 0.5em;
    }
    .date{
      font-size: 1.2rem;
      color: #8f8f8f;
    }
  }
  &.products{
    .c-news__list{
      a{
        align-items: center;
      }
      .ttl{
        margin-bottom: 0;
        font-size: 1.2em;
      }
      p{
        display: block;
      }
    }
  }
  @include mq(sm){
    width: 67%;
    .c-btn01:before{
      bottom: 2.9rem;
    }
    &__list{
      .ttl{
        font-size: 1.7rem;
        line-height: 1.4;
        margin-bottom: 0.5em;
      }
    }
  }
  @include mq(xs){
    width: 100%;
    .c-btn01:before{
      bottom: 0rem;
    }
    &__list{
      display: block;
      li{
        width: 100%;
        margin: 0;
        a{
          display: flex;
          justify-content: space-between;
        }
        p{
          display: none;
        }
        .img{
          width: 50%;
          padding-top: 40%;
        }
        .txt_area{
          width: 45%;
        }
        .ttl{
          font-size: 1.5rem;
        }
      }
    }
    &.products{

      .c-news__list{
        display: flex;
        justify-content: space-between;
        li{
          width: 47%;
          &:nth-child(n+3){
            margin-top: 2em;
          }
        }
        a{
          display: block;
        }
        .img{
          width: 100%;
          padding-top: 80%;
        }
        .txt_area{
          width: 100%;
        }
        .ttl{
          margin-bottom: 0.5em;
          font-size: 1.2em;
        }
        p{
          display: block;
          text-align: right;
        }
      }
    }
  }
}

.p-news_detail{
  &__box{

  }
  &__head{
    time{
      font-weight: 600;
      line-height: 1;
      margin-bottom: 1em;
      display: block;
    }
    .title{
      font-size: 2.8rem;
      margin-bottom: 1em;
      padding-bottom: 1em;
      border-bottom: 1px solid ;
      line-height: 1.4;
    }
  }
  &__body{

  }
  @include mq(xs){
    time{
      font-size: 0.9em;
    }
    .title{
      font-size: 2.2rem;
    }
  }
}

/*--------------------------------------------
 PARTS - product
--------------------------------------------*/
.c-products{
  &__inner{
    .prdct_list{
      flex-wrap: wrap;
      li{
        width: 31.3%;
        @include mq(xs-){
          margin-right: 3.05%;
          &:nth-child(3n){
            margin-right: 0;
          }
          &:nth-child(n+4){
            margin-top: 4rem;
          }
        }
        a{
          display: block;
          .img{
            border:1px solid #D9D9D9;
            border-radius:10px;
            overflow: hidden;
            height: 19.2rem;
            margin-bottom: 0.6em;
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .txt{
            .ttl{
              line-height: 1.8;
              margin-bottom: 0.7em;
            }
            .price{
              line-height: 1;
              span{
                font-size: 1.4rem;
              }
            }
          }
          @include mq(sm-){
            &:hover{
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
  @include mq(sm){
    &__inner{
      .prdct_list{
        li{
          a{
            .img{
              height: 15rem;
            }
          }
        }
      }
    }
  }
  @include mq(xs){
    &__inner{
      .prdct_list{
        justify-content: space-between;
        li{
          width: 48%;
          &:nth-child(n+3){
            margin-top: 2em;
          }
          a{
            .img{
              height: 13rem;
            }
            .txt{
              .ttl{
                line-height: 1.6;
              }
            }
          }
        }
      }
    }
  }
}

/*--------------------------------------------
 PARTS - product
--------------------------------------------*/
.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  ul{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li{
  }
  .current{
    a{
      background: $main_color;
      color:#fff;
      pointer-events: none;
    }
  }
  a,button{
    font-weight: 600;
    padding: 0.5em;
    border: 1px solid #35414b;
    background: #fff;
    line-height: 1;
    margin: 0 0.3em;
    transition-duration: 0.3s;
    cursor: pointer;
    @include mq(sm-){
      &:hover{
        background: $main_color;
        color:#fff;
      }
    }
  }
}

/*--------------------------------------------
  オンラインショップ
--------------------------------------------*/
.js-tab_wrap{
  #koyomido{
    display: none;
  }
}

.cart-page,
.member-page{
  padding: 5rem 0;
}
.cart_explanation{
  font-size: .9em;
  line-height: 1.6;
  margin-bottom: 1em;
  border: 1px solid;
  padding: 1em;
}

.footer_explanation{
  padding: 1.5em;
  font-size: 0.9em;
}

.shipping_free_message{
  font-weight: 600;
  font-size: 1.2em;
  em{
    font-style: normal;
    font-size: 1.5em;
  }
}

.p-online_single{
  &__inner{
    // flex-direction: row-reverse;
    justify-content: space-between;
    .c-side{
      width: 20%;
    }
    .prdct_box{
      width: 75.8%;
      justify-content: space-between;
      margin: 0 auto;
      .slide_box{
        width: 47%;
        .m-slide{
          border-radius:10px;
          overflow: hidden;
          border:1px solid #D9D9D9;
          margin-bottom: 1rem;
          &__item{
            width: 100%;
            padding-top: 100%;
          }
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .thumb{
          &__item{
            border-radius: 10px;
            overflow:hidden;
            img{
              width: 100%;
            }
          }
          .slick-dots{
            position: static;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            > *{
              width: 20%;
              padding: 0 .5rem;
              height:auto;
              margin: 0;
              &:nth-child(n+6){
                margin-top: 1rem;
              }
            }
          }
        }
      }
      .info{
        width: 48%;
        .ttl{
          font-size: 2.4rem;
          line-height: 1.4;
          margin-bottom: 3.5rem
        }
        .comment{
          line-height: 1.5;
          margin-bottom: 4rem;
        }
        ul.dtal{
          margin-bottom: 4rem;
          li{
            display: flex;
            align-items: baseline;
            &:before{
              content: "・";
            }
            .item{
              width: 15%;
            }
            .txt{
              width: 85%;
              line-height: 1.5;
            }
          }
        }
        .price_box{
          background:#EAE6E5;
          padding: 2.8rem 2.1rem;
          margin-bottom: 1.5em;
          .caption{
            font-size: .8em;
            line-height: 1.6;
            margin-top: 1em;
            background: rgba(255,255,255,0.8);
            display: block;
            border-radius: 5px;
            padding: 1em;
            margin-bottom: 1em;
          }
          .item_option{
            margin-top: 0.5em;
            width: 100%;
            p{
              display: block;
              font-weight: 600;
              font-size: 1.2em;
              position: relative;
              padding-left: 2.5em;
              margin-bottom: 0.5em;
              &:before{
                content: "";
                background: url(/assets/img/common/icon_ribon.png) no-repeat;
                background-size: contain;
                width: 42px;
                height: 37px;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
            @include mq(xs){
              p{
                &:before{
                  width: 42px;
                  height: 30px;
                  top: -0.2em;
                }
              }
            }
          }
          .inner{
            margin-bottom: 1.5rem;
            .price{
              font-size: 3.2rem;
              font-weight: 600;
              margin: 0.3em 0;
              display: flex;
              align-items: baseline;
              flex-wrap: wrap;
              span{
                font-size: 1.6rem;
                font-weight:500;
                margin-left: 0.5em;
              }
            }
            .point{
              font-size: 1.3rem;
              display: flex;
              line-height: 1;
              align-items: baseline;
              width: 100%;
              p{
                font-weight: normal;
              }
              em{
                font-style: normal;
                font-size: 1.3em;
              }
            }
            .sku{
              width: 100%;
              margin-bottom: 1.5em;
              dl{
                border: none;
              }
              dt{
                padding-right: 0;
                padding-left: 0;
                background: none;
                font-weight: 500;
                border: none;
                font-size: 1.4rem;
                width: 4em;
              }
              dd{
                width: 82.5%;
              }
              span{
                display: block;
                width: 6em;
              }
              select{
                width: 100%;
              }
            }
            .quantity{
              width: 100%;
              align-items: center;
              margin-bottom: 0.5em;
              span{
                font-size: 1.4rem;
                display: block;
                width: 4em;
                &:last-child{
                  margin-left: 0.5em;
                  width: 1em;
                }
              }
              p{
                width: 80%;
                text-align: right;
                font-size: 1.3rem;
              }
              input{
                font-size: 1.6rem;
                max-width: 66%;
                width: 100%;
                padding: 1.1em 1em;
                line-height: 1;
                max-height: 35px;
                text-align: right;
                margin-right: 0.5em;
              }
            }
          }
        }
        .wrapping_bnr{
          a{
            display: block;
            @include mq(sm-){
              &:hover{
                opacity: 0.6;
              }
            }
          }
          img{
            max-width: 100%;
          }
        }
      }
      .c-products.single{
        margin-top: 7.5rem;
        width: 100%;
      }
    }
  }
  @include mq(sm){
    &__inner{
      .c-side{
        width: 100%;
      }
      .prdct_box{
        width: 100%;
        .info{
          .ttl{
            font-size: 2.2rem;
          }
          .comment{
            line-height: 1.5;
            margin-bottom: 2rem;
          }
          ul.dtal{
            li{
              .item{
                width: 25%;
              }
              .txt{
                width: 75%;
              }
            }
          }
          .price_box{
            padding: 2rem;
            .item_option{
              th{
                width: 30%;
              }
            }
            .inner{
              display: block;
              .price{
                width: 100%;
                text-align: right;
              }
              .sku{
                width: 100%;
                margin-bottom: 2em;
              }
              input{
                margin-bottom: 0em;
              }
              .quantity{
                width: 100%;
                span{
                  &:first-child{
                    width: 4.5em;
                    display: block;
                  }
                }
                input{
                  max-width: 70%;
                }
                .txt{
                  width: 1em;
                }
                p{
                  width: 89%;
                }
                // input{
                //   max-width: 13em;
                // }
              }
            }
          }
        }
      }
    }
  }
  @include mq(xs){
    &__inner{
      .prdct_box{
        display: block;
        .slide_box{
          width: 100%;
        }
        .info{
          width: 100%;
          .ttl{
            margin-bottom: 2rem;
          }
          .comment{
            line-height: 1.5;
            margin-bottom: 2rem;
          }
          .price_box{
            .inner{
              .price{
                font-size: 3.4rem;
              }
            }
          }
        }
      }
    }
  }
}

/*--------------------------------------------
  オンラインショップ　カート
--------------------------------------------*/

#wc_cart{
  #cart_table{
    @include mq(xs){
      font-size: 12px;
    }
  }
}
.error_message{
  color: #8a0202;
}

#primary #cart_table th{
  background-color: #35414b;
  color: #fff;
}
#primary #wc_cart #cart .upbutton input{
  color: #35414b;
  border: 1px solid #35414b;
  display: inline-block;
  width: auto;
  padding: 0.5em 2em;
  margin-bottom: 0;
}
#primary #wc_cart #cart .upbutton input:hover{
  background-color: #e9f0f7;
}

#primary .send input.to_customerinfo_button,
#primary .send input.to_memberlogin_button,
#primary .send input.to_deliveryinfo_button,
#primary .send input.to_confirm_button,
#primary .send input#purchase_button{
  background-color: #35414b;
  border-radius:5rem;
  border:2px solid #35414b;
  transition-duration: 0.3s;
  max-width: 300px;
  padding: 1em;
}
#primary .send input.continue_shopping_button,
#primary .send input.to_customerinfo_button{
  border-radius:5rem;
  max-width: 300px;
  padding:1em;
  transition-duration: 0.3s;
}

@include mq(sm-){
  #primary .send input.continue_shopping_button:hover{
    background: #d1d1d1;
  }
  #primary .send input.to_customerinfo_button:hover,
  #primary .send input.to_memberlogin_button:hover,
  #primary .send input.to_deliveryinfo_button:hover,
  #primary .send input.to_confirm_button:hover,
  #primary .send input#purchase_button:hover{
    background-color: #fff;
    color: #35414b;
  }
}
@include mq(xs){
  #primary .send input.continue_shopping_button{
    margin-bottom: 1em;
  }
}

#wc_cart .footer_explanation{
  border: 2px solid #d61313;
  padding: 0.5em;
  color: #d61313;
  font-weight: 600;
}

@include mq(xs){
  /*-カートtable-*/
  #wc_system_container #primary #cart table tfoot th {
    font-weight: bold;
    font-size: 110%
  }
  #wc_system_container #primary #cart table tfoot th {
    padding-bottom: 6px;
    font-size: 110%;
  }
  #wc_system_container #primary #cart tfoot td.num,
  #wc_system_container #primary #cart tfoot td.thumbnail,
  #wc_system_container #primary #cart table tfoot th.num,
  #wc_system_container #primary #cart table tfoot th.thumbnail{
    display: none;
  }
  #wc_system_container #primary #cart td.aright{
    font-size: 110%;
    padding-bottom: 6px;
  }
  #wc_system_container #cart table {
    border: 1px solid #4f4f4f;
  }
  #wc_system_container #cart thead {
    display: none;
  }
  #wc_system_container #cart th, #wc_system_container #cart td {
    display: list-item;
    list-style-type: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-top: #ccc;
    border-right: #ccc;
    border-bottom: #ccc;
    border-left: #ccc;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 0;
    padding-left: 8px;
  }
  #cart th {
    background-color: #f8f8f8;
    color: #000;
  }
  #cart tbody tr:nth-child(2) .action:last-child, table#confirm_table tr:last-child td {
    border-bottom: 1px solid #dedede;
  }
  #wc_system_container #cart table tfoot {border-top: solid 1px #ccc;}
  
  #wc_system_container #cart table tfoot tr :last-child {font-size: 0;line-height: 1;padding-top: 0px;}
  /*-商品番号-*/
  #wc_system_container #cart tbody .num {
    padding-bottom: 8px;
    background-color: #efefef;
    color: #4f4f4f;
    text-align: center;
  }
  #cart tbody .num:before {
    content: 'No.';
    margin-right: 5px;
  }
  /*-商品画像-*/
  #wc_system_container #cart tbody .thumbnail {
    float: left;
    width: 70px;
    text-align: left;
  }
  /*-商品名-*/
  #wc_system_container #cart tbody .productname {
    float: right;
    width: calc(100% - 80px);
    text-align: left;
    line-height: 1.4;
  }
  /*-商品価格-*/
  #wc_system_container #cart tbody .unitprice {
    clear: both;
    padding-top: 0;
    font-size: 14px;
    font-weight: 700;
  }
  #cart tbody .unitprice:before {
    content: '金額（税込）：';
    margin-right: 10px;
    font-size: 12px;
    font-weight: 500;
  }
  /*-数量-*/
  #wc_system_container #cart tbody .quantity {
    text-align: right;
    margin-bottom: 0.2em;
  }
  #cart tbody .quantity input {
    width: 48px;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    padding: 2px 4px;
    box-sizing: border-box;
  }
  #cart tbody .quantity:before {
    content: '数量：';
    margin-right: 10px;
    font-size: 12px;
    font-weight: 500;
  }
  /*-金額（税込）-*/
  #wc_system_container #cart tbody .subtotal {
    padding-top: 0px;
    font-size: 18px;
    font-weight: 700;
  }
  #cart tbody .subtotal:before {
    content: '金額（税込）：';
    margin-right: 10px;
    font-weight: 500;
    font-size: 13px;
  }
  /*-在庫状態-*/
  #cart tbody .stock {
    display: none;
  }
  /*-在庫状態-*/
  #wc_system_container #cart .action {
    // padding-top: 20px;
    padding-bottom: 20px;
  }
  /*-合計金額-*/
  #cart tfoot .num, #cart tfoot .thumbnail, #cart tfoot .stock, #cart tfoot .action {
    display: none;
  }
  #wc_system_container #cart tfoot th {}
  #cart tfoot .thumbnail+th {
    padding-top: 13px;
  }
  #cart tfoot .subtotal {
    text-align: right;
    padding-top: 3px;
    padding-bottom: 20px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    font-family: "Nunito", sans-serif;
  }
  #cart td .delButton {
    padding: 8px 20px;
    background: #ccc;
    border: none;
    color: #101010;
    font-size: 12px;
    width: auto;
    margin: auto;
  }
  /*-数量更新ボタン-*/
#wc_system_container #primary #cart .upbutton {
    position: relative;
    padding-right: 0;
    padding-bottom: 30px;
    margin-bottom: 20px;
    text-align: justify;
    line-height: 2;
  }
#wc_system_container #primary #cart .upbutton input {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 92px;
    height: 30px;
    padding: 0;
    background: #fff;
    font-size: 12px;
  }
  div.header_explanation, div.footer_explanation {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #wc_system_container #inside-cart .send .to_customerinfo_button {
    margin-right: auto;
    margin-bottom: 15px;
  }
  
}

/*--------------------------------------------
  オンラインショップ　お客様情報
--------------------------------------------*/
#customer-info{
  font-size: 1.5rem;
  line-height: 1.6;
  .merit{
    border: 1px solid #ddd;
    padding: 1em;
    max-width: 40rem;
    margin: 2em auto;
  }
  .httl{
    background: #35414b;
    color: #fff;
    text-align: center;
    margin-bottom: 1em;
  }
  p{
    &:before{
      content: "";
      background: url(/assets/img/common/icon_point.png) no-repeat;
      background-size: contain;
      width: 7rem;
      height: 7rem;
      display: block;
      margin: 0 auto 0.5em;
    }
  }
}
#primary #customer-info .send{
  border-top-color: #9dacb9;
}
#primary #customer-info h5{
  background-color: #9dacb9;
  padding: 1em;
  color: #fff;
}
#primary #wc_customer .send input.to_reganddeliveryinfo_button {
  background-color: #544141;
  border-radius:5rem;
  max-width: 300px;
  padding: 1em;
  margin:0;
  border:1px solid #544141;
  transition-duration: 0.3s;
}
#primary .back_cart_button{
  border-radius:5rem;
  max-width: 300px;
  padding: 1em;
  transition-duration: 0.3s;
}
@include mq(sm-){
  #primary .back_cart_button:hover{
    background:#d1d1d1;
  }
  #primary #wc_customer .send input.to_reganddeliveryinfo_button:hover{
    background:#fff;
    color: #544141;
  }
}

@include mq(xs){
  #primary .customer_form input#loginmail{
    width: 100%;
  }
  #primary .customer_form tr.inp1 input{
    width: 90%;
  }
  #primary .customer_form tr.inp1 td{
    width: 100%;
  }
  #primary #name_row #name2,
  #primary #furikana_row #name4{
    margin-top: 1em;
  }
  #primary .customer_form input#mailaddress1,
  #primary .customer_form input#mailaddress2{
    width: 100%;
  }
  #primary .customer_form input[type="password"]{
    width: 100%!important;
  }
  #primary #customer-info .send input{
    margin-bottom: 1em;
  }
}

/*--------------------------------------------
  オンラインショップ　発送・支払方法
--------------------------------------------*/
#primary .send input.back_to_customer_button,
#primary .send input.to_confirm_button{
  border-radius:5rem;
  max-width: 300px;
  padding:1em;
  transition-duration: 0.3s;
}
@include mq(sm-){
  #primary .send input.back_to_customer_button:hover{
    background: #d1d1d1;
  }
}

@include mq(xs){
  input:not([type=radio]):not([type=checkbox]){
    margin-bottom: 1em;
  }
}

.customkey_wrapping_all td:after {
  content:"複数の商品をラッピングされる場合は「無料ラッピング」もしくは「有料ラッピング（400円）」を選択してください。 例えば3つの商品のうち2つだけまとめラッピングする場合、「備考」にまとめてラッピングしたい商品名を記載してしてください。";
  display:block;
  font-size: 1.4rem;
}

/*--------------------------------------------
  オンラインショップ　内容確認
--------------------------------------------*/
#primary .send input#back_button.back_to_delivery_button{
  border-radius:5rem;
  max-width: 300px;
  padding: 1em;
  transition-duration: 0.3s;
}
@include mq(sm-){
  #primary .send input#back_button.back_to_delivery_button:hover{
    background: #d1d1d1;
  }
}

/*--------------------------------------------
  オンラインショップ　購入完了
--------------------------------------------*/
#primary .c-ttl01{
  text-align: center;
  display: block;
}
#primary .cart_page_title{
  text-align: center;
}
#primary #wc_ordercompletion .back_to_top_button{
  background-color: #35414b;
  border-radius:5rem;
  border:2px solid #35414b;
  transition-duration: 0.3s;
  color: #fff;
  padding:1em 2em;
}
@include mq(sm-){
  #primary #wc_ordercompletion .back_to_top_button:hover{
    background:#fff;
    color: #35414b;
  }
}

/*--------------------------------------------
  会員　ログイン
--------------------------------------------*/

.member-page{
  .member_page_title{
    text-align: center;
    margin-bottom: 1em;
  }
  #loginform{
    background: #EFEFEF;
    padding: 3em;
    margin-bottom: 1rem;
  }

  .member-box{
    #nav{
      margin-bottom: 2em;
      a{
        font-weight: 600;
        &::before{
          content: "";
          background: url('/assets/img/common/icon_arrow_br04.png') no-repeat;
        }
        @include mq(sm-){
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.loginbox .forgetmenot input{
  width: 1.2em;
  height: 1.2em;
}
#primary #wc_login .c-ttl01 .en{
  margin-bottom: 0.6em;
}
#primary .submit{
  text-align: center;
}
#primary #wc_login .loginbox #member_login,
#primary #wc_member .loginbox #member_login{
  border-radius:5rem;
  background:#35414B;
  border:1px solid #35414B;
  max-width: 300px;
  padding: 1em;
  margin: auto;
}
#primary #wc_login .loginbox .new-entry #nav a,
#primary #wc_member .loginbox .new-entry #nav a{
  border-radius:5rem;
  background:#35414B;
  border:1px solid #35414B;
  max-width: 400px;
  width: 100%;
  padding: 1em;
}
@include mq(sm-){
  #primary #wc_login .loginbox #member_login:hover,
  #primary #wc_member .loginbox #member_login:hover,
  #primary #wc_login .loginbox .new-entry #nav a:hover,
  #primary #wc_member .loginbox .new-entry #nav a:hover{
    background:#fff;
    color: #35414B;
  }
}
#primary #wc_login .loginbox .new-entry{
  text-align: center;
}

/*--------------------------------------------
  会員 新パスワード取得
--------------------------------------------*/
#primary #wc_lostmemberpassword #member_login{
  border-radius:5rem;
  background:#35414B;
  border:1px solid #35414B;
  max-width: 300px;
  margin-bottom: 0;
  // padding: 1em;
}
#wc_lostmemberpassword #loginform{
  margin-bottom: 2em!important;
}
#wc_lostmemberpassword .loginbox input.loginmail{
  margin-bottom: 1.5em!important;
}
#primary #wc_lostmemberpassword .submit{
  padding: 0;
}
#primary #wc_lostmemberpassword .comment{
  margin-bottom: 0.5em;
}
@include mq(sm-){
  #primary #wc_lostmemberpassword #member_login:hover{
    background:#fff;
    color: #35414B;
  }
}
#primary #wc_lostmemberpassword #nav{
  text-align: center;
}
#primary #wc_lostmemberpassword #nav a{
  background: #e6e1e1;
  border:1px solid #e6e1e1;
  border-radius:5rem;
  padding: 1em 4em;
}
@include mq(sm-){
  #primary #wc_lostmemberpassword #nav a:hover{
    background: #fff;
  }
}

/*--------------------------------------------
  会員 マイページ
--------------------------------------------*/
#primary #memberinfo h3{
  margin: 5.142856em 0 1.42857em;
}
#primary .member-page .c-ttl01 .en{
  margin-bottom: 0.7em;
}
#primary .member_submenu{
  display: flex;
  max-width: 600px;
  margin: auto;
}
#primary .member_submenu li{
  max-width: 300px;
  width: 100%;
  margin: auto;
}
#primary .member_submenu a{
  border-radius: 5rem;
  width: 100%;
  background:#35414B;
  border:1px solid #35414B;
  color: #fff;
}
@include mq(sm-){
  #primary .member_submenu a:hover{
    background:#fff;
    color: #35414B;
  }
}

#primary .member-page #memberinfo .send input.top,
#primary .member-page #memberinfo .send input.deletemember,
#primary .member-page #memberinfo .send input.editmember{
  border-radius: 5rem;
  max-width: 300px;
  margin: auto;
}
#primary .member-page #memberinfo .send input.editmember{
  background: #35414B;
  border:1px solid #35414B;
  transition-duration: 0.3s;
}
@include mq(sm-){
  #primary .member-page #memberinfo .send input.editmember:hover{
    background: #fff;
    color: #35414B;
  }
}

@include mq(xs){
  #primary .member_submenu{
    display: block;
  }
  #primary .member_submenu .member-edit{
    margin-bottom: 1em;
  }
  #primary .member-page #memberinfo .send input.top,
  #primary .member-page #memberinfo .send input.editmember{
    margin-bottom: 1em;
  }
}

/*--------------------------------------------
  会員 新規入会フォーム
--------------------------------------------*/
#primary .customer_form input#search_zipcode{
  width: auto;
}

#primary .member-page .send input{
  background: #35414B;
  border:1px solid #35414B;
  border-radius:5rem;
  transition-duration: 0.3s;
  max-width: 300px;
  padding:1em;
}
@include mq(sm-){
  #primary .member-page .send input:hover{
    background: #fff;
    color: #35414B;
  }
}

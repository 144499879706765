@charset "utf-8";
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9ovsm1');
  src:  url('../fonts/icomoon.eot?9ovsm1#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9ovsm1') format('truetype'),
    url('../fonts/icomoon.woff?9ovsm1') format('woff'),
    url('../fonts/icomoon.svg?9ovsm1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon($type){
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @if $type == "mail" {
    content: "\e900";
  }
  @else if $type == "phone" {
    content: "\e942";
  }
  @else if $type == "circle-right" {
    content: "\ea42";
  }
}

@charset "utf-8";
/*--------------------------------------------
  INDEX
--------------------------------------------*/
.p-idx_key{
  $amp : &;
  margin-bottom: 10rem;
  &__inner{
    li{
      margin-right: 1rem;
      height: 58rem;
      img{
        border-radius: 10px;
        // max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .mv_txt{
      text-align: center;
      .inner{
        display: inline-block;
        position: relative;
        line-height: 1;
        padding: 4rem 0 3rem;
        color: #5c5c5c;
        &:before,
        &:after{
          content: "";
          display: block;
          position: absolute;
          top:50%;
          transform: translateY(-50%);
        }
        &:before{
          background: url('/assets/img/index/idx_mv_deco01.png') no-repeat;
          width: 166px;
          height: 104px;
          left: -19rem;
        }
        &:after{
          background: url('/assets/img/index/idx_mv_deco02.png') no-repeat;
          width: 147px;
          height: 80px;
          right: -19rem;
        }
        .en{
          font-size: 4.8rem;
          margin-bottom: 0.2em;
          display: block;
        }
      }
    }
  }
  @include mq(sm){
    margin-bottom: 8rem;
    &__inner{
      li{
        margin-right: 2rem;
        width: 50%;
        height: 30rem;
      }
    }
  }
  @include mq(xs){
    margin-bottom: 6rem;
    &__inner{
      li{
        margin-right: 1rem;
        width: 80%;
        height: 25rem;
      }
      .mv_txt{
        .inner{
          font-size: 1.3rem;
          &:before,
          &:after{
            display: none;
          }
          .en{
            font-size: 3.8rem;
          }
        }
      }
    }
  }
}

.p-idx_info{
  padding-bottom:10rem;
  &__inner{
    justify-content: space-between;
  }
  .ttl_box{
    justify-content: space-between;
  }
  @include mq(xs){
    &__inner{
      display: block;
    }
    .ttl_box{
      align-items:center;
    }
  }
}

.c-products.idx{
  margin-bottom: 9rem;
  @include mq(xs){
    margin-bottom: 8rem;
  }
}

.p-idx_shop{
  position: relative;
  padding-top: 9.5rem;
  margin-bottom: 18rem;
  &:before{
    content: "";
    background:#EAE6E5;
    width: 100%;
    height: 30rem;
    display: block;
    position: absolute;
    top:0;
    left: 0;
  }
  &__inner{
    position: relative;
    z-index: 2;
    .box_wrap{
      justify-content: space-between;
      .box{
        width: 47.5%;
        .img{
          border-radius:10px;
          overflow: hidden;
          margin-bottom: 2.5rem;
          img{
            max-width: 100%;
          }
        }
        .ttl{
          font-size: 2.1rem;
          font-weight: 600;
          line-height: 1;
          margin-bottom: 0.8em;
        }
        .inner{
          justify-content: space-between;
          align-items: flex-end;
          .info{
            line-height: 1.8;
          }
        }
      }
    }
  }
  @include mq(sm){
    margin-bottom: 13rem;
  }
  @include mq(xs){
    padding-top: 8rem;
    margin-bottom: 5rem;
    &__inner{
      .box_wrap{
        display: block;
        .box{
          width: 100%;
          &:first-child{
            margin-bottom: 2em;
          }
          .ttl{
            font-size: 1.8rem;
          }
          .inner{
            display: block;
            .c-btn01{
              max-width: 50%;
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}

.p-idx_about{
  position: relative;
  // padding-top:7rem;
  padding-bottom:8rem;
  margin-bottom: 5rem;
  // &:before,
  // &:after{
  //   content: "";
  //   background:#EAE6E5;
  //   display: block;
  //   height: 37rem;
  //   position: absolute;
  // }
  // &:before{
  //   width: 56%;
  //   right: 0;
  //   top:0;
  //   border-radius:15rem 0 0 15rem;
  // }
  // &:after{
  //   width: 36%;
  //   left:0;
  //   bottom:0;
  //   border-radius:0 15rem 15rem 0;
  // }
  &__inner{
    position: relative;
    z-index: 2;
    .img{
      border-radius: 20px;
      overflow: hidden;
      img{
        max-width: 100%;
      }
    }
    .txt{
      width: 45%;
      background:#EAE6E5;
      border-radius:10px;
      position: absolute;
      right: 0;
      top: 73%;
      transform: translateY(-50%);
      padding-top: 45%;
      border-radius:50%;
      .inner{
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%,-50%);
        width: 80%;
      }
      .sm{
        font-size: 1.9rem;
        line-height: 1.5;
        margin-bottom: 0.7em;
      }
      p{
        margin-bottom: 1.5em;
      }
      .c-btn02{
        margin-left: auto;
      }
    }
  }
  @include mq(sm){
    &__inner{
      .txt{
        width: 65%;
        padding-top: 65%;
        right: 0;
        top:20%;
        .inner{
          width: 70%;
        }
        .sm{
          font-size: 1.7rem;
        }
        p{
          margin-bottom: 1em;
        }
      }
    }
  }
  @include mq(xs){
    padding-bottom:0rem;
    &:after{
      display: none;
    }
    &__inner{
      .img{
        margin-bottom: 1em;
      }
      .txt{
        position: static;
        transform: translateY(0);
        width: 100%;
        border-radius:initial;
        background: none;
        padding-top: 0;
        .inner{
          width: 100%;
          transform: translate(0);
          position:static;
        }
        p{
          line-height: 1.9;
        }
        .c-btn02{
          margin: auto;
        }
      }
    }
  }
}

.p-idx__bnr{
  text-align: center;
  padding-bottom:15rem;
  a{
    display: inline-block;
    @include mq(sm-){
      &:hover{
        opacity: 0.6;
      }
    }
  }
  img{
    max-width: 100%;
  }
  @include mq(xs){
    width: 92%;
    margin: 0 auto;
    padding-bottom:10rem;
  }
}

.p-idx_design{
  position: relative;
  padding-bottom:10rem;
  &:before{
    content: "";
    background:#EAE6E5;
    width: 100%;
    height: 28rem;
    display: block;
    position: absolute;
    left: 0;
    bottom:0;
  }
  &__inner{
    position: relative;
    z-index: 2;
    .read{
      align-items: flex-end;
      justify-content: space-between;
      max-width: 840px;
      margin: 0 auto 6rem;
      .txt{
        width: 70%;
        .lg{
          font-size: 1.9rem;
          line-height: 1;
          margin-bottom: 1em;
        }
        p{
          line-height: 1.8;
        }
      }
    }
    .img{
      justify-content: space-between;
      div{
        border-radius:10px;
        overflow: hidden;
        width: 47%;
        img{
          max-width: 100%;
        }
      }
    }
  }
  @include mq(xs){
    padding-bottom:8rem;
    &:before{
      height: 25rem;
    }
    &__inner{

      .read{
        max-width: 100%;
        margin: 0 auto 3rem;
        .c-ttl01{
          text-align: center;
          width: 100%;
        }
        .txt{
          width: 100%;
          .lg{
            font-size: 1.8rem;
          }
          p{
            margin-bottom: 1em;
          }
        }
        .c-btn02{
          margin:0 auto;
        }
      }
      .img{
        div{
          width: 49%;
        }
      }
    }
  }
  .p-idx_sd_bnr{
    max-width: 400px;
    margin: 3em auto 0;
    width: 100%;
    text-align: center;
    line-height: 1;
    a{
      background: $main_color;
      display: block;
      padding:2em;
      color: #fff;
      border-radius: 10px;
      border: 1px solid $main_color;
      span{
        position: relative;
        padding-right: 1em;
        &:before{
          content: "";
          background: url('/assets/img/common/icon_arrow_w.png') no-repeat;
          width: 12px;
          height: 16px;
          display: block;
          position: absolute;
          right: 0;
          top:50%;
          transform: translateY(-50%);
          transition-duration:0.3s;
        }
      }
      @include mq(sm-){
        &:hover{
          background: #fff;
          color: $main_color;
          span{
            &:before{
              background: url('/assets/img/common/icon_arrow_br.png') no-repeat;
            }
          }
        }
      }
    }
    @include mq(sm){
      max-width: 550px;
    }
  }
}

.p-idx_insta{
  padding-top:10rem;
  &__inner{
    .read{
      text-align: center;
      margin-bottom: 6rem;
    }
  }
}
